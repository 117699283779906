/**
 * Shows and hides logos
 * 
 */
function handleLogoToggle(event) {

    let chunkSize = 6;
    if (window.innerWidth > 849) {
        chunkSize = 8;
    }

    let container = document.querySelector(`section.clients div.clientLogos`);
    if (!revealLogos(chunkSize, container, event.target)) {
        hideLogos(chunkSize, container, event.target);
    }
}

function hideLogos(stillVisible, container, button) {
    let logos = container.querySelectorAll('.logoWrapper');
    
    button.innerText = `Show More`;

    let targets = [];

    for (let i = stillVisible; i < logos.length; i++) {
        logos[i].classList.add(`hiding`);
        logos[i].classList.remove(`show`);
        logos[i].classList.remove(`showing`);
        targets.push(logos[i]);
    }
    swapClass(targets, 'hidden', 'hiding', 300);
}

function revealLogos(amount, container, button) {
    let hiddenLogos = container.querySelectorAll('.hidden');
    if (!hiddenLogos || !hiddenLogos.length) {
        return false;
    }
    if (hiddenLogos.length < amount) {
        amount = hiddenLogos.length;
        button.innerText = `Show Less`;
    }

    targets = []
    for (let i = 0; i < amount; i++) {
        targets.push(hiddenLogos[i]);
        hiddenLogos[i].classList.remove('hidden');
        hiddenLogos[i].classList.remove('hiding');
        hiddenLogos[i].classList.add('showing');
    }
    swapClass(targets, 'show', 'showing', 1000);
    return true;
}
function swapClass(elements, addClass, removeClass, delay) {
    setTimeout(() => {
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(addClass);
            elements[i].classList.remove(removeClass);
        }
    }, delay);
}

window.onload = () => {
    const button = document.querySelector(`.clients button`);
    button.addEventListener('click', handleLogoToggle);
    button.click();
};
